<template>
  <div class="login-box">
    <el-card class="box-card">
      <div class="flex flex-h-center">
        <el-menu :default-active="tab" mode="horizontal" @select="handleSelect">
          <el-menu-item index="wechat" class="margin-x mt"><span class="mt1">{{$t('微信登录')}}</span></el-menu-item>
          <el-menu-item index="phone" class="margin-x"><span class="mt1">{{$t('手机登录')}}</span></el-menu-item>
        </el-menu>
      </div>
      <el-divider class="mt0"></el-divider>

      <div v-if="tab == 'wechat'" style="height: 400px;">
        <iframe :src="src" width="100%" height="100%" frameborder="0"></iframe>
      </div>
      <el-form ref="form" :model="form" label-width="0" v-if="tab == 'phone'">
        <el-form-item class="margin-lt">
          <el-input :placeholder="$t('请输入手机号码')" v-model="form.phone" class="input-with-select">
            <el-select @change="saveType" v-model="type" slot="prepend" placeholder="">
              <el-option :label="$t('+86（中国）')" value="+86"></el-option>
              <el-option :label="$t('+61（澳大利亚）')" value="+61"></el-option>
              <el-option :label="$t('+62（印度尼西亚）')" value="+62"></el-option>
              <el-option :label="$t('+60（马来西亚）')" value="+60"></el-option>
              <el-option :label="$t('+91（印度）')" value="+91"></el-option>
              <el-option :label="$t('+63（菲律宾）')" value="+63"></el-option>
              <el-option :label="$t('+1（加拿大）')" value="+1"></el-option>
              <el-option :label="$t('+64（新西兰）')" value="+64"></el-option>
              <el-option :label="$t('+886（台湾）')" value="+886"></el-option>
              <el-option :label="$t('+852（香港）')" value="+852"></el-option>
              <el-option :label="$t('+853（澳门）')" value="+853"></el-option>
              <el-option :label="$t('+44（英国）')" value="+44"></el-option>
              <el-option :label="$t('+81（日本）')" value="+81"></el-option>
              <el-option :label="$t('+82（韩国）')" value="+82"></el-option>
              <el-option :label="$t('+001（美国）')" value="+001"></el-option>
              <el-option :label="$t('+65（新加坡）')" value="+65"></el-option>
              <el-option :label="$t('+66（泰国）')" value="+66"></el-option>
              <el-option :label="$t('+84（越南）')" value="+84"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-form-item class="margin-lt">
          <el-input :type="flag ? 'text' : 'password'" v-model="form.password" :placeholder="$t('请输入密码')">

            <div @click="setFlag()" class="pointer" :class="{ 'password-view': !flag }" slot="suffix">
              <i class="el-input__icon el-icon-view"></i>
            </div>

          </el-input>
        </el-form-item>


        <el-form-item class="margin-lt">
          <div class="flex flex-between">
            <el-input :placeholder="$t('请输入验证码(数字相加所得的结果)')" class="flex1" v-model="form.captcha"></el-input>
            <el-image class="pointer margin-l" @click="loadPicCaptcha" :src="pic_captcha_src" style="width:100px">
            </el-image>
          </div>
        </el-form-item>


        <div class="flex flex-between">
          <!-- <a class="st" href="/regist">{{$t('立即注册')}}</a> -->
          <a class="st ul" href="/regist?flag=1">{{$t('忘记密码')}}</a>
        </div>

        <el-form-item>
          <el-button class="block" round type="primary" @click="login">{{$t('登录')}}</el-button>

        </el-form-item>
        <div class="flex flex-h-center"><span class="st1">{{$t('还没有账号')}}! </span><a href="/regist"
            class="margin-l st ul">{{$t('立即注册')}}</a></div>

      </el-form>


    </el-card>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      tab: "wechat",
      type: "+86",
      form: {
        phone: '',
        password: '',
        captcha: '',
        key: ''
      },
      pic_captcha_src: "",
      flag: false
    }
  },

  computed: {
    src: () => {
      return "https://open.weixin.qq.com/connect/qrconnect?appid=wx62ed850a6918bf20&redirect_uri=" +
        encodeURIComponent("https://www.ptefighter.com/login") +
        "&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect";
    },
    refer: () => {
      let path = localStorage.getItem("login_refer") || '/';
      if (path.indexOf("/login") == 0) {
        path = "/"
      }
      return path;
    }
  },

  created() {
    this.type = localStorage.getItem("phone_type") || "+86";
    this.loadPicCaptcha();
    let code = this.$route.query.code;
    if (code) {
      this.$http.post("/api/login/wechat", { code: code }).then((res) => {
        localStorage.setItem("token", res.token);
        this.$store.commit("setUser", res.user);
        this.$message({
          message: '登录成功!',
          type: 'success'
        });
        this.$router.replace("/main");
      });
    }
  },
  methods: {
    saveType(val) {
      localStorage.setItem("phone_type", val);
    },

    setFlag() {
      this.flag = !this.flag;
    },
    handleSelect(index) {
      this.tab = index;
    },
    loadPicCaptcha() {
      this.$http.get("/captcha/api/math").then((data) => {
        this.pic_captcha_src = data.img;
        this.form.key = data.key;
      });
    },

    login() {
      let reg = /^1[0-9]{10,10}$/;
      if (this.type == "+86" && !reg.test(this.form.phone)) {
        this.$message({
          message: '手机号码错误！',
          type: 'primary'
        });
        return;
      } else if (this.form.password.length < 6) {
        this.$message({
          message: '密码最少6位！',
          type: 'primary'
        });
        return;
      } else if (this.form.captcha.length == 0) {
        this.$message({
          message: '请输入验证码',
          type: 'primary'
        });
        return;
      } else {
        this.form["phone_type"] = this.type;
        this.$http.post("/api/login", this.form).then((res) => {
          localStorage.setItem("token", res.token);
          this.$store.commit("setUser", res.user);
          this.$message({
            message: '登录成功!',
            type: 'success'
          });
          this.$router.replace("/main");
        }).catch(() => {
          this.loadPicCaptcha();
        })
      }
    }
  },
};
</script>

<style scoped>
.login-box {
  height: 600px;
  width: 450px;
  margin: 80px auto;

}

.el-select {
  width: 160px;
}

.input-with-select>>>.el-input-group__prepend {
  background-color: #fff;
  color: #E6A23C;
}

.el-form-item {
  margin-top: 30px;
}

.btn {
  margin-left: 20px;
}

.login-box>>>.el-input-group__append {
  padding: 0 !important;
}
</style>
